/* ReportInfo.css */
.report-info-container {
    width: calc(100% - 40px);
    margin: 20px auto 0;
    background-color: #FFFFFF;
    color: black;
    text-align: left;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.report-info-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.report-info-section {
    margin-bottom: 20px;
}

.section-3 {
    margin-top: 10px;
}

.section-1 {
    display: flex;
    justify-content: space-between;
}

.left-column {
    width: 55%;
    /* Adjust the width as needed */
}

.right-column {
    width: 30%;
    /* Adjust the width as needed */
}

.report-info-section-header {
    color: var(--text-title);
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: 500;
}

.report-info-header h3 {
    color: var(--text-title);
    font-size: 16px;
    margin: 0 0 20px 0;
    font-weight: 600;
}

.report-info-section p {
    margin: 0;
    color: var(--text-title);
    font-size: 13px;
    font-weight: 420;
}

.right-column img {
    max-width: 100%;
    height: auto;
}

.divider {
    height: 1px;
    background-color: #E8EAF3;
    width: 100%;
    margin: 0 auto;
    /* This will center the divider */
}

.snp-container {
    margin: 13px 0 20px;
}

.snp-title {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.gene-container {
    display: flex;
}

.rs-genotype-container {
    display: flex;
    justify-content: space-between;
    width: 250px;
}

.rs-container {
    display: flex;
    margin-right: 20px;
    min-width: 20%;
}

.snp-title .gene {
    font-weight: bold;
    color: var(--text-title);
}

.rs-id,
.genotype {
    font-size: 11px;
    font-weight: 500;
    color: var(--light-gray-text);
}

.snp-overview {
    color: var(--text-title);
    width: 97%;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
}

.label {
    margin-right: 10px;
    font-size: 11px;
    font-weight: 600;
    color: var(--text-title);
}

.genotype-container {
    display: flex;
    margin-right: 20px;
}

.snp-title .gene {
    color: #5542D1;
    font-weight: medium;
    font-size: 11px;
}

.section-header {
    cursor: pointer;
    user-select: none;
}

.section-header-section2 {
    display: flex;
    margin-top: 40px;
    cursor: pointer;
    user-select: none;
}

.hidden-left, .hidden-right {
    position: relative;
    padding-top: 28px;
}

.hidden-left::after, .hidden-right::after {
    content: '';
    position: absolute;
    top: 28px;
    bottom: 20px;
    pointer-events: none;
    border-radius: 18px;
}

.hidden-left::after {
    left: 18px;
    right: 30%;
    background: linear-gradient(to right, #e9ebf4, #e9ebf4a8, transparent, transparent);
}

.hidden-right::after {
    left: 30%;
    right: 18px;
    background: linear-gradient(to left, #e9ebf4, #e9ebf4a8 50%, transparent 75%, transparent);
}

.arrow-bottom {
    margin-bottom: 25px;
    padding-top: 10px;
}

.arrow-bottom.hidden-right::after {
    top: 10px;
}

.arrow-top > span, .arrow-bottom > span {
    position: absolute;
    font-weight: 600;
    font-size: 12px;
}

.arrow-top > span {
    top: 5px;
}

.arrow-bottom > span {
    bottom: -2px;
}

.gene-tested {
    left: 30%;
}

.gene-tested::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    left: -30px;
    top: 10px;
    background-size: 60%;
    background-image: url('../../assets/table-arrow-left.svg');
}

.primary-functions {
    left: 25%;
}

.primary-functions::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    left: -30px;
    bottom: 10px;
    background-size: 60%;
    background-image: url('../../assets/table-arrow-left.svg');
    transform: scaleY(-1);
}

.genome-location {
    left: 20%;
}

.test-result {
    right: 22%;
}

.genome-location::after, .test-result::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    right: -47px;
    top: 10px;
    background-size: 60%;
    background-image: url('../../assets/table-arrow-right.svg');
}


.meaning-section h4 {
    display: none !important;
}

.meaning-section {
    margin-bottom: 20px !important;
    background: none !important;
    border: none !important;
}

.meaning-title {
    color: #5542D1 !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
}

.meaning-section ul {
    list-style-type: disc !important;
    list-style-position: outside !important;
    margin: 0 0 1em 1.5em !important;
    padding-left: 20px !important;
    background: transparent !important;
    border: none !important;
    display: block !important;
}

.meaning-section li {
    display: list-item !important;
    list-style-type: disc !important;
    background: transparent !important;
    border: none !important;
    margin: 0 0 6px 0 !important;
    padding: 0 !important;
    color: var(--text-title) !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
}


@media screen and (max-width: 1239px) {

    .report-info-container {
        /* max-width: 60%; */
        /* margin: 20px 0 0 0; */
    }
}

@media screen and (max-width: 1050px) {
    .section-1 {
        flex-direction: column-reverse;
        align-items: center;
    }

    .left-column {
        width: 100%;
    }

    .right-column {
        width: unset;
        padding: 20px;
    }

    .report-info-header h3 {
        /* .report-info-header h3:not(.personal-supplement-title) { */
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .snp-title {
        gap: 20px;
    }

    .gene-container,
    .rs-container,
    .genotype-container {
        flex-direction: column;
        margin: 0;
    }

    .gene-container,
    .rs-genotype-container {
        width: 50%;
    }

    .snp-overview {
        width: 100%;
    }
}