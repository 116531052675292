.sort-togglable {
  font-size: 13px;
  line-height: 18px;
}

.sort-togglable span { 
  border-radius: 8px;
}

.sort-togglable span.active {
  background-color: rgba(238, 240, 250, 0.5);
  color: initial;
}

.sort-togglable span:hover {
  background-color: rgba(238, 240, 250, 0.4);
  color: initial;
}