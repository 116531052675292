:root {
  --font-xs: 0.75rem;
  --font-sm: 0.875rem;
  --font-normal: 1rem;
  --font-lg: 1.25rem;
  --font-xl: 1.5rem;
  --font-2xl: 2.75rem;
  --font-4xl: 4.375rem;
}

.font-raleway {
  font-family: "raleway", sans-serif, "Times New Roman", Times, serif;
}

.font-avenir {
  font-family: "avenir", sans-serif, "Times New Roman", Times, serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #181818;
  font-weight: 900;
  font-size: var(--font-normal);
  width: 70%;
}

.signup-btn {
  border: 0px solid rgb(71, 67, 197);
  background: linear-gradient(126deg, #2b95b6 0%, #ff3c5f 0%, #9340ff 100%);
  border-radius: 25px;
  display: initial;
  font-size: var(--font-normal);
  color: #181818;
  font-weight: 900;
  padding: 0.75rem 3rem; /* Reduced padding */
  cursor: pointer;
}

.signup-btn:hover {
  background: #31356e;
  color: white;
}

/* Subscription card */
#subscriptionList-section {
  padding: 0 0.5rem; /* Reduced padding */
  width: 100%;
  background: white;
}

#subscriptionList-section div:first-child h1 {
  font-size: var(--font-xl); /* Reduced font size */
  font-weight: 400;
  text-align: center;
  margin: 0%;
}

#subscriptionList-section div:first-child h2 {
  font-size: var(--font-lg); /* Reduced font size */
  font-weight: 400;
  text-align: center;
}

#subscriptionList-section>div:last-child {
  padding: 1rem 0; /* Reduced padding */
  text-align: center;
  font-size: var(--font-sm);
}

.cards-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem; /* Reduced gap */
}

.subscription-card {
  flex: 1 1 calc(33.333% - 1rem); /* Adjusted flex basis */
  box-sizing: border-box;
  max-width: 400px; /* Reduced max-width */
}

.subscription-card img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  object-position: center;
}

.subscription-card .subscription-card-content {
  padding: 1rem; /* Reduced padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; /* Reduced gap */
  border: 1px solid #D7D7D7;
  border-top: none;
}

.subscription-card .subscription-card-content>h2 {
  font-size: var(--font-lg) !important;
  margin: 0;
  font-weight: 400;
}

.subscription-card .subscription-card-content .subscription-price div {
  display: flex;
  align-items: start;
}

.subscription-card .subscription-card-content .subscription-price div span:first-child {
  font-size: var(--font-xl);
}

.subscription-card .subscription-card-content .subscription-price div span {
  font-size: var(--font-2xl); /* Reduced font size */
}

.subscription-card .subscription-card-content .subscription-price p {
  font-size: var(--font-xs); /* Reduced font size */
  margin: 0%;
  text-align: center;
}

.subscription-card .subscription-card-content>p {
  font-size: var(--font-xs); /* Reduced font size */
  text-align: center;
}

.subscription-card .subscription-card-content>span {
  font-size: var(--font-xs);
  text-align: center;
}

.subscription-card .subscription-card-content .subscribe-button {
  padding: 0.75rem; /* Reduced padding */
  width: 100%;
  font-size: var(--font-normal);
  background: black;
  color: white;
  border: none;
  cursor: pointer;
}

.subscription-card .subscription-card-content .subscribe-button:hover {
  border: 0px solid #181818;
  background: rgba(56, 74, 211, 0.7);
}

.best-price-button {
  background: #afafaf !important;
}

.best-price-button:hover {
  background: rgba(127, 148, 99, 0.7) !important;
}

.subscription-card-content hr {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
  border: none;
}

.subscription-card-content .subscription-para {
  display: flex;
  gap: 0.5rem; /* Reduced gap */
  align-items: start;
}

.subscription-card-content .subscription-para h3 {
  font-weight: 400;
  text-align: center;
  font-size: var(--font-xs); /* Reduced font size */
  line-height: 1.5; /* Adjusted line height */
  margin: 0;
}

.tick-icon {
  padding-top: 0.1rem;
  min-width: 1rem;
  min-height: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
}

.tick-icon img {
  width: 100%;
  height: 100%;
}

/* Footer */
.main-container footer {
  width: 100%;
  background: #000;
  color: #fff;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 2rem;
  font-weight: 300;
  font-size: var(--font-sm);
}

.main-container footer div:first-child {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.main-container footer div:first-child a {
  display: flex;
  align-items: baseline;
}

.main-container footer>a {
  text-decoration: inherit;
  list-style: none;
  color: inherit;
}

@media (max-width: 768px) {
  .subscription-card {
    flex: 1 1 calc(50% - 1rem); /* Adjusted flex basis */
  }
}

@media (max-width: 480px) {
  .subscription-card {
    flex: 1 1 100%;
  }
}