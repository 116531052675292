.Navbar {
    display: flex;
    width: 100%;
    height: var(--nav-bar-height);
    background-color: #FFFFFF;
    color: var(--light-gray-text);
    /* overflow: hidden; */
    font-size: 17px;
}

.NavItem {
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
    color: var(--light-gray-text);
}

.NavItem.active {
    color: var(--text-title);
    font-weight: 600;
}

.NavItem.locked {
    pointer-events: none;
    cursor: initial;
}

.NavItem svg {
    margin-right: 4px;
    margin-bottom: -2px;
}

.nav-links-wrap {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.RightItems {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 11px;
}

.NavItemContainer {
    display: flex;
    align-items: center;
    margin-right: 20px;
    background-color: var(--light-gray-btn);
    border-radius: 8px;
}

.NavItemContainer.active {
    background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
    color: #fff;
    font-weight: 500;
}

.nav-item-togglable {
    top: 42px;
    left: -10px;
    font-size: 13px;
    line-height: 18px;
}

.nav-item-togglable span:hover {
    background-color: rgba(238, 240, 250, 0.5);
    color: initial;
    border-radius: 8px;
}

.download-button {
    position: relative;
}

.Icon {
    font-size: 14px;
    cursor: pointer;
}

.ExtraIcons {
    display: flex;
    align-items: center;
    position: relative;
}

.ExtraIcons-Dropdown {
    width: 136px;
    box-sizing: border-box;
    left: -64px;
    top: 35px;
}

.NavItem-Buttons {
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
}

.NavItemIcon {
    margin: 0 0 0 7px;
}

.IconImage {
    width: 20px;
    height: 24px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 40px;
}

.icon {
    width: 20px;
    height: 24px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    background-repeat: no-repeat;
}

.burger-icon {
    background-image: url('../../assets/burger.svg');
}

.burger-icon-active {
    background-image: url('../../assets/burger-icon-opened.svg');
}

.notif-icon {
    background-image: url('../../assets/Notification.svg');
}

.personal-supplement-button .NavItem-Buttons {
    box-sizing: border-box;
    user-select: none;
    cursor: initial;
    width: 186px;
}

.personal-supplement-button.active .NavItem-Buttons {
    width: 165px;
    user-select: initial;
}

.go-back-btn {
    display: block;
    cursor: pointer;
    color: var(--accent-violet);
}

.nav-bar-mobile-container {
    background-color: var(--middle-gray-bg);
    position: fixed;
    z-index: 100;
    padding-bottom: 10px;
    left: 0;
    right: 0;
}

.nav-bar-mobile-wrap {
    background-color: var(--middle-gray-bg);
    padding: 20px;
}

.drawer-content-wrap {
    padding: 80px 90px;
    background-color: var(--middle-gray-bg);
    /* height: 100%; */
    position: relative;
    width: 440px;
    box-sizing: border-box;
}

.drawer-content-wrap-wide {
    padding: 0 20px 40px 20px;
}

.NavItem.active + .hidden-section {
    display: none;
}

.nav-links-mobile-buttons {
    border-top: 1px solid var(--dark-gray-bg);;
    padding-top: 20px;
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.nav-links-mobile-buttons .NavItemContainer {
    margin: 0;
    border-radius: 30px;
    background: #E2E3F5;
}

.sub-links-wrap {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.sub-links {
    color: #676A95;
}

/* chips: start */
.mobile-chips {
    width: calc( 100vw - 40px );
    overflow: auto;
    display: flex;
    gap: 20px;
    font-size: 13px;
    padding: 0 20px;
    margin-bottom: 3px;
    background-color: var(--middle-gray-bg)
  }
  
  .mobile-chips-overlay {
    position: relative;
  }
  
  .mobile-chips-overlay::before, .mobile-chips-overlay::after {
    content: '';
    background-color: var(--middle-gray-bg);
    position: absolute;
    width: 20px;
    height: 100%;
    z-index: 10;
    left: 0;
  }
  
  .mobile-chips-overlay::after {
    right: 0;
    left: unset;
    top: 0;
  }
  
  .mobile-chip-wrap {
    color: var(--text-title);
    padding: 7px 15px;
    background-color: #FFFFFF;
    border-radius: 20px;
    text-wrap: nowrap;
  }
  
  .mobile-chip-wrap.active {
    color: initial;
    background-color: #E2E3F5;
  }
  /* chips: end */

  .notif-dropdown-overwrite {
    top: 30px !important;
  }

@media screen and (max-width: 1239px) {
    .nav-links-wrap {
        flex-direction: column;
        margin: auto;
    }

    .nav-links-wrap .NavItem {
        padding: 10px 0;
        margin: 0;
        font-size: 18px;
        border-bottom: 1px solid var(--dark-gray-bg);
        width: 100%;
        text-align: center;
    }

    .NavItem.active + .hidden-section {
        display: block;
    }

    .nav-item-togglable {
        top: -115px;
        left: 0;
        box-shadow: 0px -2px 7px 0px rgba(69, 70, 78, 0.3)
    }

    /* .drawer-content-wrap {
        padding: 75px 30px 30px 30px;
    } */
    
    .nav-bar-mobile-wrap-mini {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        height: 77px;
    }

    .nav-bar-mobile-wrap-mini .myoform-logo {
        visibility: hidden;
    }

    .NavItem.active {
        color: var(--text-title--text-title);
        font-weight: normal;
    }

    .NavItem-Buttons {
        padding: 10px 20px;
    }
}


@media screen and (max-width: 500px) {
    .drawer-content-wrap {
        width: 100%;
    }
}