.button-with-arrow {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  color: #fff;
  background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
}

.button-with-arrow-icon-base {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

.button-with-arrow-icon {
  background-image: url('../../assets/white-right-arrow.svg');
  filter: none;
}

.button-with-arrow:disabled {
  background-image: none;
  background-color: var(--light-gray-btn);
  color: rgba(28, 29, 34, 0.5);
  cursor: inherit;
} 

.button-with-arrow:disabled .button-with-arrow-icon-base {
  filter: contrast(.2);
}

.save-btn-icon {
  background-image: url('../../assets/check-icon.svg');
}

.add-button-icon {
  background-image: url('../../assets/plus-icon.svg');
}

.locked-button-icon-black {
  background-image: url('../../assets/locked-icon-black.svg');
  height: 20px;
  width: 20px;
}

.right-arrow-dark {
  background-image: url('../../assets/right-arrow-dark.svg');
}

.display-none{
  display: none;
}