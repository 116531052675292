/* App.css */
@import url(./components/ExpandableInfo/expandableInfo.css);
@import url(./components/VitaminsList/vitaminsList.css);
@import url(./components/Dashboard/dashboard.css);
@import url(./components/Home/home.css);
@import url(./components/ExpandButton/expandButton.css);
@import url(./components/ExpandableSection/expandableSection.css);
@import url(./components/SideNavigation/sideNavigation.css);
@import url(./components/HowToRead/howToRead.css);
@import url(./components/Questionnaire/questionnaire.css);
@import url(./components/Questionnaire/questionnaire.css);
@import url(./components/Auth/auth.css);
@import url(./components/MobileFixedFooter/mobileFixedFooter.css);
@import url(./components/PersonalSupplement/personalSupplement.css);
@import url(./components/ButtonWithIcon/ButtonWithIcon.css);
@import url(./components/admin/admin.css);
@import url(./components/DropdownSelect/dropdownSelect.css);
@import url(./components/Settings/settings.css);
@import url(./components/Notifications/notifications.css);
@import url(./components/CustomCheckbox/customCheckbox.css);

.App {
  display: flex;
  min-height: 100vh;
  background-color: #fff;
}

.LeftDiv {
  width: 100%;
  background-color: var(--middle-gray-bg);
  height: calc(100vh - var(--nav-bar-height));
  overflow: scroll;
  box-sizing: border-box;
}

.button {
  border-radius: 16px;
  padding: 11px 15px;
  width: 175px;
  height: 40px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  border: none;
  cursor: pointer;
}

/* HELPERS */
.full-width {
  width: 100%;
}

.horizontalFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verticalFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.togglable {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(69, 70, 78, 0.3);
  border-radius: 8px;
  padding: 10px;
  z-index: 99999;
  width: max-content;
}

.togglable span {
  color: var(--light-gray-text);
  padding: 6px 10px;
  user-select: none;
  display: block;
  cursor: pointer;
}

.basic-section {
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 20px;
}

.basic-page-header {
  margin: 0 0 20px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: var(--text-title);
}

.basic-page-header span {
  font-weight: 400;
}

.MuiDrawer-paper.MuiDrawer-paper {
  background-color: var(--middle-gray-bg);
}

.MuiDrawer-paperAnchorTop {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* .MuiDrawer-paperAnchorRight {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
} */

@media screen and (max-width: 1239px) {
  .LeftDiv {
    height: auto;
    padding-top: 90px !important;
  }
}

@media screen and (max-width: 500px) {
  .LeftDiv {
    padding-bottom: 110px !important;
  }
}

/* Initial state of entering element */
.fade-enter {
  opacity: 0;
}

/* State of entering element when fully entered */
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

Initial state of exiting element .fade-exit {
  opacity: 1;
}

/* State of exiting element when fully exited */
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

@media screen and (max-width: 1239px) {
  .basic-page-header {
    text-align: center;
    margin-top: 15px;
  }
}

@media screen and (max-width: 500px) {
  .basic-page-header {
    scroll-margin: 20px;
  }
}
