/* styles.css */

.custom-checkbox-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox .custom-checkbox-checkmark {
    /* margin-right: 10px; */
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    border: none;
    background-image: url('../../assets/checkmark-unchecked.svg');
}

.custom-checkbox input[type="checkbox"]:checked+.custom-checkbox-checkmark {
    background-image: url('../../assets/checkmark-checked.svg');
}

.custom-checkbox-text.checked {
    font-weight: 600;
}

.custom-checkbox-wrap.disabled {
    opacity: 0.5;
}

.custom-checkbox-wrap.disabled a {
    pointer-events: auto;
    color: #007bff !important;
    text-decoration: underline;
    opacity: 1;
}

.custom-checkbox-text.disabled {
    color: #aaa;
}

.custom-checkbox-checkmark.disabled {
    background-color: #ccc;
}