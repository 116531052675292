.password-reset-form-wrap {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  height: fit-content;
}

.password-reset-form-wrap h2 {
  margin-bottom: 10px;
  color: #333;
  font-size: 24px;
  font-weight: 500;
}

.password-reset-form-wrap p {
  margin-bottom: 20px;
  color: #666;
  font-size: 14px;
}

.reset-button {
  margin-top: 20px !important;
  background-color: #007bff !important;
  color: white;
  padding: 10px;
  font-size: 16px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #0056b3;
}

.MuiTextField-root {
  margin-bottom: 15px;
}

.MuiSnackbar-root {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
