.side-navigation-wrap {
  background-color: #fff;
  max-width: 296px;
  padding: 13px 32px 32px 32px; 
  height: 100vh;
  box-sizing: border-box;
}

.side-navigation-logo {
  margin: 0 40px 15px -7px;
  cursor: pointer;
}

.side-navigation-main {
  border-top: 1px solid #CDD1DE;
  height: calc(100vh - 100px);
}

.expendablesWrap {
  height: calc(100% - 63px);
  overflow: scroll;
}

.expendableOverwrite { 
  /* height: 100%; */
}

.side-navigation-link {
  color: var(--light-gray-text);
  display: block;
  padding: 10px 10px 10px 20px;
}

.side-navigation-link-with-dot {
  position: relative;
}

.side-navigation-link-with-dot svg {
  position: absolute;
  left: -20px;
  top: 2px;
}

.side-navigation-link.active {
  color: initial;
}

.side-navigation-link.isEdit {
  color: #6D57FC;
}

.side-navigation-sort-wrap {
  cursor: pointer;
  position: relative;
}

.side-navigation-sort-button {
  background-color: var(--light-gray-btn);
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  width: 98px;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 15px 0;
}

.side-navigation-sort-button-icon {
  background-image: url('../../assets/sort-icon.svg');
  width: 25px;
  height: 25px;
}

.side-navigation-sort-togglable {
  top: 35px;
  left: -10px;
}

/* .side-navigation-sort-togglable span.active {
  background-color: rgba(238, 240, 250, 0.5);
  color: inherit;
} */

.navigation-list {
  /* height: 100%; */
  position: relative;
  padding-top: 15px;
  height: calc(100vh - 120px);
  position: relative;
  padding-top: 15px;
  box-sizing: border-box;
}

.progress-links-item {
  display: flex;
  align-items: center;
}

.progress-links-item span:hover {
  color: #45464E;
  opacity: .9;
}

.progress-links-item svg {
  z-index: 20;
}

.navigation-list-line {
  background-image: url('../../assets/line.svg');
  background-repeat: no-repeat;
  width: 5px;
  height: 100%;
  position: absolute;
  left: 5px;
  top: 30px;
  background-size: 5px 747px;
}

/* .progress-links-item.active {

} */

.progress-links-item.active .side-navigation-link {
  color: var(--text-title);
}

.progress-links-item.active .side-navigation-link.isEdit {
  color: #6D57FC;
}

.how-to-read-content-wrap {
  padding-top: 20px;
}