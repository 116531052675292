.expandableInfoWrap {
  width: 438px;
  box-sizing: border-box;
  background-color: var(--dark-gray-bg);
  flex-shrink: 0;
  height: calc(100vh - var(--nav-bar-height));
  position: relative;
}

.expandableInfoHidableWrap {
  /* height: calc(100% - 18px - 24px); */
  height: 100%;
}

.expandableInfoWrap_hidden {
  width: 25px;
  padding: 0;
}

.expandableInfoHidable {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  height: 100%;
}

.expandableInfoWrap h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0 0 18px 0;
  color: var(--text-title);
}

.expandableInfoContent {
  padding: 18px;
}

.expandableInfoContent h3{
  margin: 0 0 23px 0;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}

.expandableInfoDescription {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
}

.footer {
  border-top: 1px solid var(--dark-gray-bg);
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  padding: 12px 18px 10px;
}

.footerText {
  color: var(--light-gray-text);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  display: block;
}

.footerButtonWrap {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(2, minmax(115px, auto));
  grid-gap: 5px;
  align-items: center;
}

.footerButtonWrap button {
  width: unset;
  padding: 11px;
}

.footerHelpButton {
  background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
  color: white;
}

.footerOrderButton {
  background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
  color: #fff;
  display: flex;
  align-items: center;
  align-items: center;
  gap: 6px;
  justify-content: center;
}

.footerOrderButton span {
  display: block;
}

.footerOrderButtonIcon {
  background-image: url('../../assets/white-right-arrow.svg');
  height: 24px;
  width: 24px;
}

.expandableInfoCollapseBtn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--dark-gray-bg);
  border: none;
  background-image: url('../../assets/two-sided-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: -15px;
  cursor: pointer;
}

@media screen and (max-width: 1439px) {
  .footer {
    padding: 12px 0 0 0;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1239px) {
  .footer {
      max-width: 400px;
  }
}