.auth-mobile-only {
  display: none;
}

.auth-desktop-only {
  display: block;
}

.sign-up-wrap,
.sign-in-wrap {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sign-up-wrap {
  background-color: #dae2eb;
  position: relative;
}

.sign-in-wrap {
  background-color: #dae2eb;
  width: 100%;
}

.sign-up-wrap::before,
.sign-in-wrap::before {
  position: absolute;
  content: "";
  bottom: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.no-content::before,
.no-content::before {
  content: none;
}

.auth-alternative-bg-0::before {
  background-image: url("../../assets/woman-bg.png");
  background-position-y: top;
  background-position-x: left;
  width: 45%;
  height: 80%;
  min-width: 700px;
  left: -5px;
}

.auth-alternative-bg-1::before {
  background-image: url("../../assets/squat-gradient-1.jpg");
  height: calc(100% - 90px);
  left: 0px;
  width: 80%;
  background-position-y: bottom;
  background-position-x: left;
}

.auth-alternative-bg-2::before {
  background-image: url("../../assets/rockclimber-transparent.png");
  height: calc(100% - 90px);
  left: 0px;
  width: 885px;
}

.auth-alternative-bg-3::before {
  background-image: url("../../assets/serfer-gradient.jpg");
  height: calc(100% - 90px);
  left: 0px;
  width: 90%;
}

.auth-alternative-bg-4::before {
  background-image: url("../../assets/sky.png");
  height: calc(100% - 90px);
  left: 0px;
  width: 95%;
}

.sign-up-wrap::after {
  position: absolute;
  content: "";
  background: linear-gradient(0deg, #a6b2bf 10%, transparent 60%);
  width: 100%;
  bottom: -30px;
  opacity: 0.5;
  height: calc(100% - 90px);
}

.no-content::before,
.no-content::before,
.no-content::after {
  content: none;
}

.sign-up-wrap-hide-gradient::after {
  background: transparent;
}

.sign-up-content,
.sign-in-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 10;
  padding: 65px 0;
}

.sign-up-content {
  margin-right: 180px;
}

.sign-in-content {
  text-align: center;
}

.sign-in-main h2 {
  font-size: 35px;
  margin: 0 0 10px 0;
}

.sign-in-main h2 + span {
  font-weight: 500;
}

.sign-up-header {
  z-index: 10;
}

.sign-up-header h1 {
  color: #090a0a;
  font-size: 60px;
  text-align: center;
}

.sign-in-main {
  width: 100%;
  /* Adjust based on your design preference */
  display: flex;
  flex-direction: column;
}

/* Button styling */
.button.auth-btn,
.button.auth-btn-accent {
  width: 273px;
  border-radius: 48px;
  height: 59px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  /* Gradient for buttons */
  margin-top: 20px;
  /* Margin on top of the button */
  cursor: pointer;
}

.button.button.auth-btn-accent {
  color: #fff;
  background-image: linear-gradient(90.44deg, #9940f6 3.9%, #6d57fc 99.63%);
}

.button.button.auth-btn-sign-up {
  margin-bottom: 20px;
}

.button.auth-btn {
  color: #090a0a;
  background-image: none;
  background-color: white;
}

.auth-btn-arrow-icon {
  background-image: url("../../assets/white-right-arrow.svg");
  height: 24px;
  width: 24px;
}

.auth-footer {
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.auth-footer-action {
  color: var(--accent-violet);
  cursor: pointer;
}

.auth-footer-logo {
  background-image: url("../../assets/logo-icon-transparent.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 157px;
  height: 145px;
  margin-top: 45px;
}

.auth-header-wrap {
  background: #fff;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  box-sizing: border-box;
  z-index: 20;
}

.sign-up-logo-desktop {
  background-image: url("../../assets/myoform-logo-web.svg");
  width: 199px;
  height: 50px;
}

.button.auth-btn.auth-create-acc-btn {
  margin-bottom: 20px;
  box-shadow: 0px 7px 11px 0px #9940f61c;
  font-weight: 500;
}

.sign-up-wrap-with-form,
.sign-in-content {
  margin-right: 285px;
}

.sign-up-form-wrap,
.sign-up-form,
.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-form {
  margin: 50px 0;
}

.sign-in-form {
  gap: 23px;
  margin: 50px 0 23px 0;
}

.sign-in-password-restore {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px !important;
  color: var(--accent-violet);
  margin-bottom: 10px;
}

.auth-footer-question {
  font-size: 14px;
}

.sign-up-form fieldset {
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.sign-up-form-names {
  gap: 10px;
}

.sign-up-form-credentials,
.sign-up-form {
  flex-direction: column;
  gap: 23px;
}

.sign-up-form-credentials input,
.sign-up-form-names input {
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e3e5e5;
  box-sizing: border-box;
  font-size: 16px;
  text-align: left;
}

.sign-up-form-credentials input:focus-visible,
.sign-up-form-names input:focus-visible {
  outline-color: var(--accent-violet);
  /* TODO to check with Jenny */
}

.sign-up-form-credentials input {
  width: 327px;
}

.sign-up-form-names input {
  width: 158.5px;
}

.sign-up-form-title {
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.sign-up-form-subtitle {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

/* The container pushing the OTP card far right on desktop */
.otp-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: -46px;
  /* Far to the right on desktop */
  margin-top: 80px;
}

/* White card for OTP */
.otp-card {
  background-color: #fff;
  width: 320px;
  padding: 24px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Heading for OTP */
.otp-heading {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

/* Subtext for instructions */
.otp-subtext {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 16px;
}

/* Container for the input so we can center or adjust spacing easily */
.otp-input-container {
  margin-bottom: 20px;
}

/* The OTP <input> – shorter width */
.otp-input {
  width: 180px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  outline: none;
  margin: 0 auto;
  border-radius: 16px;
}

/* Container for the two buttons */
.otp-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-radius: 16px;
}

/* Primary gradient button (Verify Code) */
.otp-button {
  flex: 1;
  background: #5C58FF;
  border: none;
  color: #ffffff;
  padding: 12px 16px;
  font-size: 1rem;
  border-radius: 16px;
  cursor: pointer;
  text-transform: none;
}

.otp-button:hover {
  background: rgb(102, 100, 100); 
}

/* Secondary button (cancel) */
.otp-button-secondary {
  background: #ccc;
  color: #333;
}

/* Mobile-only logo container (hidden by default) */
.mobile-logo-container {
  display: none;
}

.mobile-logo {
  width: 80px;
  height: auto;
  display: block;
  margin: 30px auto 0 auto;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1024px) {

  /* Center the card for tablets/smaller laptops */
  .otp-wrapper {
    justify-content: center;
    margin-right: 44px;
    margin-top: 60px;
  }
}

@media screen and (max-width: 650px) {
  .otp-card {
    width: 90%;
    max-width: 320px;
    margin: 0 20px;
  }

  .mobile-logo-container {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .sign-up-content {
    padding: 0 0 140px 0;
    margin: auto 40px auto 0;
  }

  .sign-in-content {
    margin-right: 40px;
  }

  .sign-up-header h1 {
    font-size: 65px;
  }
}

@media screen and (max-width: 850px) {
  .sign-up-wrap::before {
    width: 530px;
    height: 700px;
    background-size: contain;
  }
}

@media screen and (max-width: 650px) {
  .sign-up-wrap {
    display: block;
  }

  .sign-up-wrap::before {
    width: 423px;
    height: 574px;
    bottom: -30px;
    left: -30px;
  }

  .sign-in-wrap {
    background-color: var(--middle-gray-bg);
  }

  .sign-up-wrap.sign-up-wrap-hide-gradient {
    background-color: var(--middle-gray-bg);
  }

  .sign-in-wrap::before {
    content: none;
  }

  .sign-in-password-restore {
    text-align: start;
  }

  .sign-up-content,
  .sign-in-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin-right: none;
  }

  .sign-up-content {
    padding: 80px 0;
  }

  .sign-in-content {
    padding: 50px 20px 70px;
    margin-right: 0;
  }

  .sign-in-logo {
    background-image: url("../../assets/logo-mini.svg");
    height: 40px;
    width: 40px;
    margin: auto;
  }

  .sign-up-header h1 {
    font-size: 38px;
  }

  .sign-up-logo {
    background-image: url("../../assets/logo-dark-bg.png");
    width: 190px;
    height: 50px;
    margin: auto;
  }

  .sign-in-main {
    width: 330px;
  }

  .sign-in-main h2 {
    font-size: 25px;
  }

  .sign-in-main span {
    font-size: 16px;
    display: block;
  }

  .sign-in-form {
    display: flex;
    flex-direction: column;
    gap: 23px;
    align-items: center;
    margin: 23px 0;
  }

  .auth-footer {
    /* z-index: 10; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .button.auth-btn {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    width: 330px;
  }

  .button.auth-btn-accent {
    height: 48px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(90.44deg, #9940f6 3.9%, #6d57fc 99.63%);
    color: #fff;
  }

  .auth-mobile-only {
    display: block;
  }

  .auth-desktop-only {
    display: none !important;
  }
}

.password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f6f8;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.submit-button {
  margin-top: 20px;
}

.error-text {
  font-size: 0.875rem;
  text-align: left;
  color: red;
}
