@font-face {
  font-family: 'raleway';
  src: url('../src/assets/fonts/Raleway-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'avenir';
  src: url('../src/assets/fonts/AvenirLTStd-Book.otf');
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: #1C2B36;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:-webkit-any-link {
  text-decoration: none;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  /* sizes */
  --nav-bar-height: 60px;

  /* colors */
  --dark-gray-bg: #E8EAF3;
  --text-title: #45464E;
  --middle-gray-bg: #F4F5FA;
  --light-gray-text: #8E95A9;
  --accent-violet: #9940F6;
  --light-gray-btn: #EFEFF0;
  --accent-light-violet: #9390F9;
}
/*  */