.home-wrap {
  padding: 90px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.home-titles-wrap {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.home-title {
  font-size: 66px;
  font-weight: 600;
  line-height: 1.2;

  margin: 0 0 30px 0;
}

.home-subtitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 23px;
  display: block;
  text-align: center;
}

.big-cards {
  margin-bottom: 50px;
}

.cards-wrap-big-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* 2 columns */
  gap: 20px;
}

.cards-wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  text-align: left;
}

.home-big-card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 303px;
  height: 334px;
  padding: 46px 19px 35px 26px;
  border-radius: 12px;
  color: var(--text-title);
  box-sizing: border-box;
  box-shadow: 0px 8px 32px 0px #3326ae14;
  background: linear-gradient(339.52deg, #ffe0fe 29.72%, #d591ff 124.35%);
}

.big-card-title {
  white-space: pre-line;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
}

.home-big-card-wrap p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.home-big-card-wrap.active {
  color: #fff;
  background: linear-gradient(171.29deg, #9940f6 -6.58%, #908eff 77.15%);
}

.big-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.big-card-footer-button.button {
  border-radius: 30px;
  padding: 9px 18px;
  width: 145px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.big-card-footer-button.locked.button {
  color: #31356f;
  background-color: #fff;
  background-image: none;
}

.big-card-footer-button.locked.button:disabled .button-with-arrow-icon-base {
  filter: contrast(1);
}

.big-card-dash-btns.button {
  margin-bottom: 30px;
}

/* .small-cards {
    
} */

.small-card-wrap {
  width: 194px;
  height: 195px;
  padding: 19px 15px 25px 15px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.locked-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
}

.small-card-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  color: var(--text-title);
  padding-top: 15px;
  border-top: 1px solid #d3d3d3;
  white-space: pre-line;
  width: 100%;
  margin-top: 17px;
}

.genetic-card {
  width: 600px;
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: linear-gradient(180deg, #000, #3533cd);
  border-radius: 12px;
  padding: 20px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.completed-kit-bg {
  background: linear-gradient(to right, #000, #3533cd);
}

.genetic-card-content {
  /* max-width: 80%; */
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.description {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
}

.genetic-card-button {
  background-color: #945df8;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 30px;
  padding: 9px 18px;
  font-size: 14px;
}

.genetic-card-image img {
  margin-top: auto;
  width: 10rem;
}

.errorClass {
  color: red;
  font-size: smaller;
}

@media screen and (max-width: 1000px) {
  .home-wrap {
    padding: 80px;
  }

  .home-title {
    /* font-size: 25px; */
    font-size: 50px;
    margin: 0 0 15px 0;
  }
}

@media screen and (max-width: 786px) {
  .home-wrap {
    padding-top: 100px;
  }

  .big-cards {
    grid-template-columns: 1fr;
  }

  .home-titles-wrap {
    flex-direction: column-reverse;
  }

  .home-title {
    font-size: 25px;
    margin: 0 0 30px 0;
  }

  .home-subtitle-greetings {
    margin-bottom: 10px;
  }

  .genetic-card {
    width: 303px;
    flex-direction: column;
    box-sizing: border-box;
  }

  .genetic-card-content {
    max-width: 100%;
  }
}

@media screen and (max-width: 782px) {
  .small-cards {
    justify-content: center;
  }
}

@media screen and (max-width: 601px) {
  .big-cards {
    grid-template-columns: 1fr;
  }

  .home-big-card-wrap {
    width: 311px;
    height: 281px;
    padding: 26px;
  }

  .small-card-wrap {
    width: 145px;
    height: 165px;
  }
}

@media screen and (max-width: 501px) {
  .home-wrap {
    padding: 101px 32px 130px 32px;
  }

  .small-cards {
    justify-content: center;
  }
}
