.MainContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Content {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--middle-gray-bg);
}