.mobile-fixed-footer-wrap {
  position: fixed;
  background-color: var(--middle-gray-bg);
  height: 100px;
  width: 100%;
  padding: 15px 20px 30px 20px;
  box-sizing: border-box;
  bottom: 0;
  /* display: grid;
  grid-template-columns: minmax(207px, 272px) 50px;
  grid-gap: 10px; */
  display: flex;
  align-items: center;
  z-index: 1000;
  justify-content: center;
  gap: 10px
}

.button.mobile-fixed-footer-btn {
  background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
  color: #fff;
  /* width: 100%; */
  width: 272px;
  height: 48px;
  border-radius: 16px;
  padding: 11px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.button.mobile-fixed-footer-btn span {
  display: block;
}

.mobile-fixed-footer-btn-icon {
  content: url('../../assets/lock-icon.svg');
}

.mobile-fixed-footer-up {
  background-color: #fff;
  background-image: url('../../assets/up-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  height: 48px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}