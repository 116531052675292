.genetic-instruction .basic-section .basic-page-header {
  text-align: left;
  margin-top: 0;
}

.genetic-instruction .basic-section a {
  font-size: 13px;
  line-height: 1.4;
}

.package-images {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
}

.package-item {
  text-align: center;
}

.package-item p {
  width: 70%;
  margin: auto;
}
.package-item img {
  width: 20rem;
  height: auto;
  margin-top: 2rem;
}

.video {
  position: relative;
  height: 327px;
  overflow: hidden;
  max-width: 603px;
  background: #fff;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .package-images {
    flex-direction: column;
    margin-top: 2rem;
    gap: 3rem;
  }
}
