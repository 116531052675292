.questionnaire-container {
    padding: 20px;
    box-sizing: border-box;
}

.react-select-container {
    width: 100%;
}

.react-select-control {
    border-radius: 8px !important;
    min-height: 48px !important;
}

/* .react-select-multivalue {
    border-radius: 8px !important;
    background-color: var(--accent-violet) !important;
    color: #fff !important;
} */

.questionnaire-container-button {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    background-color: rgba(94, 99, 102, 0.1);
    cursor: pointer;
    font-size: 14px;
}

.questionnaire-container-button.selected {
    background-color: var(--accent-violet);
    color: #fff;
}

.questionnaire-container-button {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    background-color: rgba(94, 99, 102, 0.1);
    cursor: pointer;
    font-size: 14px;
}

.questionnaire-container-button.selected {
    background-color: var(--accent-violet);
    color: #fff;
}

.questionnaire-container-button.selected-submitted {
    background-color: var(--accent-light-violet);
    color: #fff;
}

.questionnaire-container-button.edit-selected {
    background-color: var(--accent-violet);
    color: #fff;
}

.questionnaire-stepper {
    margin-bottom: 20px;
}

/* .questionnaire-question {
    margin: 0 0 20px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    color: var(--text-title);
} */

.question-label {
    margin: 0 0 15px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
}

.question-wrap {
    padding: 22px 0;
    /* margin-bottom: 22px; */
    border-bottom: 1px solid var(--dark-gray-bg);
}

.question-wrap:last-child {
    margin: 0;
}

.question-input-wrap {
    margin-top: 15px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.question-input-wrap-select-like {
    max-width: 330px;
}

.questionnaire-edit-btns-wrap {
    display: flex;
    gap: 10px;
}

/* .questionnaire-action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
} */

.questionnaire-button {
    width: 150px;
}

.questionnaire-linearProgress {
    margin-top: 10px;
}

.questionnaire-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 16px;
}

/* Styling for free text input */
.questionnaire-textarea {
    width: 100%;
    height: 150px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 16px;
    resize: vertical;
}

.questionnaire-finish-message {
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
}

.questionnaire-container .questionnaire-action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
}

.questionnaire-container .questionnaire-button {
    font-weight: 500;
    /* width: 273px;
    height: 48px;
    border-radius: 48px; */
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
    color: #fff;
}
/* 
.questionnaire-container .questionnaire-button:hover {
} */

.questionnaire-container .questionnaire-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.questionnaire-genetic-upload {
    height: 150px;
}

.question-intro-text{
    margin-bottom: -12px;
    padding-top: 21px;
}

/* @media screen and (max-width: 1239px) {
    .questionnaire-question {
        text-align: center;
    }
  } */

  .custom-input-wrap {
      position: relative;
      margin-bottom: 15px;
  }

  .custom-input {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
  }

  .input-error {
      border-color: red;
  }

  .error-message {
      color: red;
      font-size: 12px;
      position: absolute;
      top: 110%;
      left: 0;
  }