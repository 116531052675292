.expandable-section {
  justify-content: flex-start !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reverse {
  flex-direction: row-reverse;
}

.expandable-header-wrap span {
  display: block;
}

.expandable-header-wrap {
  display: flex;
  cursor: pointer;
  padding: 10px 0;
}

.expandable-toggle {
  display: flex;
  align-items: center;
  gap: 5px;
}

.expandable-toggle span {
  font-size: 14px;
  white-space: nowrap;
}