.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContainer {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 1200px;
    width: 90%;
    height: 80vh;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

iframe {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border: none;
}