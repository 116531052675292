.additional-container {
  margin-top: 20px;
  /* padding: 20px; */
  color: black;
  text-align: center;
}

.score-calc-info-section {
  padding: 20px;
}

.score-calc-card {
  width: calc(100% - 40px);
  background-color: #FFFFFF;
  color: black;
  text-align: left;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.score-calc-description {
  display: flex;
  font-size: 13px;
  font-weight: 400;
}

.score-calc-table {
  margin-top: 23px;
}

.score-calc-table-fixed {
  max-width: 100%;
}

.score-calc-table-body {
  font-size: 12px;
  line-height: 19px;
  margin-top: 23px;
  padding-right: 7px;
}

.score-calc-table-arrow {
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}

.score-calc-table-arrow-left {
  background-image: url('../../assets/table-arrow-left.svg');
}

.needs-margin {
  margin-left: 20%;
}

.score-calc-table-arrow-right {
  background-image: url('../../assets/table-arrow-right.svg');
  margin-left: auto;
}

.score-calc-table-gene-row td {
  font-weight: 600;
  padding: 10px 0;
}

.score-calc-table-gene-row span {
  margin-left: 10px;
}

.score-calc-table th {
  font-weight: 600;
}

.score-calc-table-gene-span {
  color: #6D57FC;
}

.score-calc-table-align-right {
  text-align: end;
}

.score-calc-table-gene-info-span {
  color: var(--light-gray-text);
}

.score-calc-table-gene-info-explanation {
  font-size: 13px;
  font-weight: 400;
}

/* .nutritional-report-title { 
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-title);
  scroll-margin: 20px; 
 } */

.card-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

/* .nutrition-report-highlighted {
  padding: 20px 0 0 20px;
  border-left: 1px dashed #6D57FC;
  border-top: 1px dashed #6D57FC;
  border-top-left-radius: 15px;
} */


@media screen and (max-width: 1439px) {
  .score-calc-table-fixed {
    max-width: 100%;
  }

}
/* 
@media screen and (max-width: 1239px) {
  .nutritional-report-title {
    text-align: center;
  }
} */

@media screen and (max-width: 768px) {

  .card-container {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(207px, 1fr));
    grid-template-rows: auto;
    grid-gap: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: fit-content;
    margin: auto;
  }

  .score-calc-table {
    margin-top: 0;
  }

  .score-calc-table-body {
    margin-top: 0;
    padding-right: 0;
  }

  .score-calc-table-gene-row td {
    padding: 0 0 20px 0;
  }
}

@media screen and (max-width: 465px) {

  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}