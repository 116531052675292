.settings-wrap {
  padding: 20px;
}

.settings-form-wrap {
  /* 40px -- padding of basic section; 44px -- header of the page */
  /* height: calc(100% - 40px - 44px); */
  margin: 0 !important;
}

.settings-form-wrap,
.settings-form-wrap form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.settings-form-wrap form label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
  margin: 22px 0 15px 0;
}

.settings-form-wrap form label.settings-form-first-label {
  margin: 0 0 15px 0;
}

.settings-divider {
  margin: 22px 0;
  border-top: 1px solid var(--dark-gray-bg);
}

.settings-form-question {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #A7A9CA;
  cursor: pointer;;
}

.settings-form-wrap footer {
  border-top: 1px solid var(--dark-gray-bg);
  padding: 18px 33px 0 0;
  width: calc(100% + 40px);
  box-sizing: border-box;
  margin-left: -20px;
  margin-top: 124px;
}

.settings-form-wrap.mobile-class {
  margin-left: 0;
}

.save-btn {
  margin-left: auto !important;
}