.personal-supplement-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.drawer-content-wrap .personal-supplement-wrap {
  padding: 0;
}

.personal-supplement-wrap .report-info-container {
  margin-top: 0;
  flex: 1;
  overflow: scroll;
}

.expandableContainerStyle {
  flex-grow: 1;
}

.personal-supplement-locked-screen {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(232,234,243, .8)
}

.personal-supplement-locked-screen img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.personal-supplement-lowered.basic-page-header {
  margin-top: 85px;
}

.personal-supplement-lowered + .personal-supplement-locked-screen {
  top: 129px;
  border-radius: 15px;
}

.personal-supplement-cross {
  display: none;
}

@media screen and (max-width: 601px) {
  .personal-supplement-cross {
    display: block;
    background-image: url('../../assets/cross-icon.svg');
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}