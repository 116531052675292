
.nutrition-card {
    width: calc(207px - 24px - 20px);
    height: calc(200px - 16px);
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 12px 24px 16px 20px;
}

.cpb-text {
    font-weight: bold;
}

/* .card-divider {
    width: 75%;
    height: 10px;
    background-color: #E8EAF3;
    margin: 3px auto 0 auto;
} */

.card-top {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 74px;
    width: 74px;
    margin: 10px auto 15px;
}

.card-bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid var(--dark-gray-bg);
}


.card-name {
    font-weight: 500;
    font-size: 14px;

    color: var(--text-title);
}

.card-severity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.card-severity {
    text-align: center;
    color: var(--light-gray-text);
    font-weight: bold;
    font-size: 14px;
    /* padding-left: 16px; */
    display: flex;
    align-items: center;
}
/* 
.card-severity::after {
    content: '';
    background-image: url('../../assets/right-arrow.svg');
    width: 30px;
    height: 30px;
    display: block;
} */

.arrow {
    background-image: url('../../assets/right-arrow.svg');
    width: 22px;
    height: 22px;
}

@media screen and (max-width: 465px) {
    .nutrition-card {
        height: 195px;
        width: 160px;
        box-sizing: border-box;
    }

}