.notifications-wrap {
    width: 270px;
    top: 35px;
    right: 0;

    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(69, 70, 78, 0.3);
    border-radius: 8px;
    padding: 15px;
    z-index: 99999;
}

.notifications-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 5px 10px;
    box-sizing: border-box;
    align-items: baseline;
}

.notifications-header span {
    display: block;
}

.notifications-title {
    background: linear-gradient(to right, #9940F6 3.9%, #6D57FC 99.63%);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.notifications-read-all-btn {
    cursor: pointer;
}

.notifications-read-all-btn,
.notifications-timestamp {
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: var(--light-gray-text);
}

.notifications-message {
    color: #1C2B36;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
}

.notifications-message.viewed,
.notifications-dot.viewed {
    color: var(--light-gray-text);
}

.notifications-dot {
    height: 6px;
    width: 6px;
    background: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 15px;
}

.notifications-item {
    border-top: 1px solid var(--dark-gray-bg);
    box-sizing: border-box;
    padding: 10px 0 10px 25px;
    cursor: pointer;
    position: relative;
}

.notifications-item:hover {
    background-color: rgba(238, 240, 250, 0.5);
}

.notifications-item:hover .notifications-item::after {
    content: '';
    position: absolute;

}