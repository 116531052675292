.payment-status-container {
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f7f7f7;
  }

  .status-box {
    width: 30%;
    padding: 2rem 3rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .status-box h1{
    margin: 0%;
  }

  .status-success {
    font-size: 24px;
    font-weight: bold;
    color: #38a169; /* Green color */
  }

  .status-failure {
    font-size: 24px;
    font-weight: bold;
    color: #e53e3e; /* Red color */
  }

  .status-message {
    margin-top: 10px;
    font-size: 16px;
    color: #4a4a4a;
  }

  .back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3182ce; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }

  .back-button:hover {
    background-color: #2c5282; /* Darker blue on hover */
  }

  .back-button:focus {
    outline: none;
  }
