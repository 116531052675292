ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

ul li {
  list-style: none;
  border: 1px solid #d7d7d7;
  background-color: #F4F4FA;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
}

ul li div {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  text-align: left;
  gap: 0.5rem;
}

ul li div span:first-child {
  font-weight: 600;
}

@media (max-width: 768px) {
    ul{
        grid-template-columns: repeat(1, 1fr);
    }
  }
  