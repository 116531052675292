.expandBtn {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  /* color: var(--light-gray-text); */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.expandBtn::before {
  content: '';
  background-image: url('../../assets/down-arrow.svg');
  transform: rotate(180deg);
  background-position: left;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.expandBtn.expandBtn_rotated::before {
  background-position: right;
  transform: none;
}

.expandBtn-two-colors.expandBtn::before {
  background-image: url('../../assets/up-arrow.svg');
  transform: none;
  background-position: center;
  width: 20px;
  height: 20px;
}

.expandBtn-two-colors.expandBtn.expandBtn_rotated::before {
  background-image: url('../../assets/down-arrow.svg');
  background-position: center;
  width: 20px;
  height: 20px;
}