.how-to-read-wrap {
  padding: 20px;
}

/* .how-to-read-wrap h2 {
  margin: 0 0 20px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: var(--text-title);
} */

.how-to-read-wrap h3 {
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
}

.how-to-read-wrap p {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  max-width: 850px;
}

.how-to-read-wrap p:not(:first-of-type) {
  margin-top: 20px;
}

.how-to-read-disclaimer-wrap {
  margin-top: 30px;
}

.how-to-read-disclaimer-wrap span {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  max-width: 805px;
}

.how-to-read-disclaimer-button.button {
  font-size: 14px;
  line-height: 1.2px;
  margin-top: 25px;
}

.how-to-read-disclaimer-button.button:disabled {
  opacity: 0.5;
  color: #fff;
  background-image: linear-gradient(90.44deg, #9940f6 3.9%, #6d57fc 99.63%);
}

.how-to-read-disclaimer-button.button:disabled .save-btn-icon {
  filter: contrast(1);
}

.how-to-read-disclaimer-button.button span {
  font-weight: 600;
  font-size: 14px;
}

.how-to-read-video-container {
  position: relative;
  height: 327px;
  overflow: hidden;
  max-width: 603px;
  background: #fff;
  margin-top: 20px;
}

/* @media screen and (max-width: 1239px) {
  .how-to-read-wrap h2 {
      text-align: center;
  }
} */
