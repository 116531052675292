.vitaminListWrap {
  margin-top: 10px;
  overflow: scroll;
}

.vitaminsListList {
  margin-top: 20px;
}

.vitaminsListItemWrap {
  font-weight: 500;
  font-size: 11px;
  line-height: 28px;
  border-bottom: 1px solid #F1F3F9;
}

.vitaminsListItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.vitaminsListItem span {
  display: block;
}

.vitaminsListItemName {
  color: var(--text-title);
}

.vitaminsListItemName.isExpended {
  color: #5542D1;
  font-weight: 500;
}

.vitaminsListItemQuantity {
  color: var(--light-gray-text);
  white-space: no-wrap;
}

.vitaminsListItemJustification {
  font-weight: 400;
  line-height: 16px;
  text-align: justify;
  margin-bottom: 12px;
}

@media screen and (max-width: 1439px) {
  .vitaminsListList {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .vitaminListWrap {
    margin-top: 0;
}
}