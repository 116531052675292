.loading-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loading-spinner {
    border: 16px solid transparent;
    border-top: 16px solid #9940F6;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
    position: relative;
    box-shadow: 0 0 15px #9940F6;
}

.loading-spinner:before {
    content: "";
    position: absolute;
    top: -16px;
    left: -16px;
    right: -16px;
    bottom: -16px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: #9940F6;
    animation: spin 2s linear infinite;
}

.loading-spinner:after {
    content: "";
    position: absolute;
    top: -16px;
    left: -16px;
    right: -16px;
    bottom: -16px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-bottom-color: #9940F6;
    animation: spin 2.5s linear infinite reverse;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}